import React from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { ColorRing } from 'react-loader-spinner'


const modal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    borderRadius: '20px',
    backgroundColor: '#fff',
    boxShadow: 24,
    p: 4,
    overflowY:'scroll',
    paddingBottom: '60px',
    height:'80%'
  };

const Book = ({getFareModal,getFareModalIsOpen,getFare,carTypes,bookNow,isLoading,subCatVal}) => {
  
  return (
    <div>
      <button
        className="btn btn-dark rounded-pill form-control mb-3 shadow-none"
        onClick={getFare}
      >
        GET FARE
      </button>

      <Modal
        open={getFareModalIsOpen}
        onClose={getFareModal}
      >
      <Box sx={modal}>
        <h3>SELECT CAR</h3>
        <hr />
        
        {isLoading ?
        <div className="text-center pt-50">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['black']}
          />
        </div>
        :
        carTypes?.car_detail?.map((cars,id)=>{
          return <div className="row mt-5" key={id}>
                      <div className="col">
                          <img src={cars.image} width={120} height={100} alt="" />
                      </div>
                      <div className="col fs-14">
                          <p>{cars.name}</p>
                          <p>{subCatVal === 'cab-from-airport' || subCatVal === 'cab-to-airport' ? '₹'+cars.amount:''}</p>
                          <p>{subCatVal === 'cab-from-airport' || subCatVal === 'cab-to-airport' ? '₹'+cars.toll+'/- Toll':''}</p>
                          <p>{cars.beta === ''?'':'₹'+cars.beta}</p>
                      </div>
                      <div className="col text-end">
                          <button className='bg-yellow rounded'
                              onClick={()=>bookNow(cars.name,cars.amount)}
                          >Book Now</button>
                      </div>
                  </div> 
        })
    
      }
      </Box> 
    </Modal>
    </div>
  )
}

export default Book

