import React, {useState, useEffect} from 'react'
import Whatsapp from '../assets/images/whatsapp_icon.webp'
import Telephone from '../assets/images/phone_icon.webp'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contacticons = () => {

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      setDeferredPrompt(e);
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 250 && !(window.matchMedia('(display-mode: standalone)').matches)) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleInstallClick = async () => {
    console.log('clicked')
    if (!deferredPrompt) {
      return;
    }
    deferredPrompt.prompt();
    const result = await deferredPrompt.userChoice;
    setDeferredPrompt(null);
  };

  // const notify = () => toast(<button onClick={handleInstallClick} style={{paddingTop: '6px'}}> Add to Home Screen </button>);

  // useEffect(() => {
  //   notify();
  // },[]);

  return (
    <>
      <a href="https://api.whatsapp.com/send?phone=919620088857"><img src={Whatsapp} alt="" style={{position: 'fixed',zIndex: '9999',bottom: '10%',right: '2%', height: '50px', width: '50px'}} className="img-fluid contact-icon-small zoom"/></a>
      <a href="tel:9620088857"><img src={Telephone} alt="" style={{position: 'fixed',zIndex: '9999',bottom: '10%',left: '2%', height: '50px', width: '50px'}} className="img-fluid contact-icon-small zoom"/></a>
      {/* {isVisible && <button onClick={handleInstallClick}  style={{position: 'fixed',zIndex: '9999',bottom: '2%',left: '41%', border: 'none', borderRadius: '10px'}}>
        Add to <br/> Home
      </button>} */}
      {/* <button onClick={notify}>Notify!</button> */}
      {/* <ToastContainer position="bottom-center" autoClose={false} style={{ width: "98%", left: "1%"}}/> */}
      {!(window.matchMedia('(display-mode: standalone)').matches) && 
        <div className="alert alert-warning alert-dismissible fade show" role="alert" style={{position: 'fixed',zIndex: '9999',bottom: '0%', left: '1%', margin: '0', width: '98%'}}>
          <strong onClick={handleInstallClick}>Add to Home Screen</strong>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      }
    </>
  )
}

export default Contacticons
