import React, { useState } from 'react'
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import PickUp from './PickUp';
import DropUp from './DropUp';
import Book from './Book';
import axios from 'axios';
import Success from './Success';
import DatePicker from './DatePicker';
import Loading from './Loading';

const Form = (props) => {
    const [fname, setFname] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isNumberValid, setIsNumberValid] = useState(true);
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState('00:00');
    const [pickUpModalIsOpen, setpickUpModalIsOpen] = useState(false);
    const [dropUpModalIsOpen, setDropUpModalIsOpen] = useState(false);
    const [getFareModalIsOpen, setGetFareModalIsOpen] = useState(false);
    const [successModalIsOpen,setSuccessModalIsOpen] = useState(false);
    const [successDetails,setSuccessDetails] = useState('');
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [droptypingTimeout, setDropTypingTimeout] = useState(null);
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);
    const handleName = (e) =>{
        let name = "";
        if (/^\s*\S/.test(e.target.value)) {
            name = e.target.value;
        } else {
            e.target.value = "";
            name = "";
        }
        setFname(name);
    }

    // console.log(phoneNumber)
    const handleNumber = (e) => {
        const numberInput = document.getElementById('phoneNumber');
        if (numberInput.value.length > 10) {
            numberInput.value = numberInput.value.slice(0, 10);
        }
        else{
            const inputValue = e.target.value;
            setPhoneNumber(inputValue);
        }
        // const phoneRegex = /^[6789]\d{9}$/;
        // const isValidPhoneNumber = phoneRegex.test(inputValue);
        // setIsNumberValid(isValidPhoneNumber);
    };

    const handleEmail = (e) =>{
        const inputEmailValue = e.target.value;
        setEmail(inputEmailValue);
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        const isValidEmail = emailRegex.test(inputEmailValue);
        setIsEmailValid(isValidEmail);
    }

    // PICK UP

    const handlePickUpModalOpen = ()=>{
        setpickUpModalIsOpen(!pickUpModalIsOpen);
    }

    const handlePickUp=(value)=>{
        props.setPickUp(value);
        // if (typingTimeout) {
        //     clearTimeout(typingTimeout);
        //   }
        //   const newTimeout = setTimeout(() => {
        //     props.fetchPickUp(value);
        //   }, 400);
        // setTypingTimeout(newTimeout);
        
    }

    const handlePickUpChoosenPlace = (placeChoosen) => {

        props.setPickUp(placeChoosen);
        // setpickUpModalIsOpen(!pickUpModalIsOpen);
    }

    // DROP UP
    
    const handleDropUpModalOpen = ()=>{
        setDropUpModalIsOpen(!dropUpModalIsOpen);
    }

    const handleDropUp=(value)=>{
        props.setDropUp(value);
        // if (droptypingTimeout) {
        //     clearTimeout(droptypingTimeout);
        //   }
        //   const newTimeout = setTimeout(() => {
        //     props.fetchDropUp(value);
        //   }, 400);
        // setDropTypingTimeout(newTimeout);
    }

    
    const handleDropUpChoosenPlace = (placeChoosen) => {
   
        props.setDropUp(placeChoosen);
        setDropUpModalIsOpen(!dropUpModalIsOpen);
    }

    // TImer

    const [ModalIsOpen, setModalIsOpen] = useState(false)
    const [showTime,setShowTime] = useState('12:00 AM')

    const handleModalOpen = ()=>{
        setModalIsOpen(!ModalIsOpen);
      }

    const selectTime = (time,show)=>{
        
        setSelectedTime(time)
        setShowTime(show)
        setModalIsOpen(!ModalIsOpen);
    }

    // GET FARE

    const getFareModal = ()=>{
        setGetFareModalIsOpen(!getFareModalIsOpen);
    }

    

      const formatDate = (date) => {
        const year = date.$d.getFullYear();
        const month = String(date.$d.getMonth() + 1).padStart(2, '0');
        const day = String(date.$d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;

      };

      const formattedDate = selectedDate !== null ? formatDate(selectedDate) : "";

      const getFare = () => {
        const dateTime = formattedDate + " " + selectedTime+":00";
        const pickupLoc = props.subCatVal === 'cab-from-airport'? 'Bangalore Airport Lounge, Kempegowda Int\'l Airport Rd, A - Block, Gangamuthanahalli, Karnataka, India' : props.pickUp
        const dropLoc = props.subCatVal === 'cab-to-airport'? 'Bangalore Airport Lounge, Kempegowda Int\'l Airport Rd, A - Block, Gangamuthanahalli, Karnataka, India' : props.dropUp
        console.log({type: props.subCatVal,
            n_ame: fname,
            mobile: phoneNumber,
            email: email,
            pickup_from: pickupLoc,
            drop_to : dropLoc,
            date_time: dateTime,
            
            pickcityLng:0,
            pickcityLat:0,
            drpcityLng:0,
            drpcityLat:0})

        if(fname === '' || phoneNumber === '' || phoneNumber.length < 10 || isNumberValid === false || email === '' || isEmailValid === false || selectedDate === null || selectedTime === null || pickupLoc === '' || dropLoc === '') {
            setSuccessModalIsOpen(!successModalIsOpen)
            setSuccessDetails('Kindly Fill all details to book Cab. Thanks !!!')
        }
        else{
            setGetFareModalIsOpen(!getFareModalIsOpen);
            props.fetchCars(props.subCatVal);
            localStorage.setItem('fname',fname);
            localStorage.setItem('phoneNumber',phoneNumber);
            localStorage.setItem('email',email);
        }
        
    }

        // BOOK NOW

      const bookNow= async (car_type, amount)=>{
        setLoadingModalIsOpen(true);
        const pickupLoc = props.subCatVal === 'cab-from-airport'? 'Bangalore Airport Lounge, Kempegowda Int\'l Airport Rd, A - Block, Gangamuthanahalli, Karnataka, India' : props.pickUp
        const dropLoc = props.subCatVal === 'cab-to-airport'? 'Bangalore Airport Lounge, Kempegowda Int\'l Airport Rd, A - Block, Gangamuthanahalli, Karnataka, India' : props.dropUp
        const dateTime = formattedDate + " " + selectedTime+":00";

        // console.log({type: props.subCatVal,
        //     n_ame: fname,
        //     mobile: phoneNumber,
        //     email: email,
        //     pickup_from: pickupLoc,
        //     drop_to : dropLoc,
        //     date_time: dateTime,
        //     return_date_time: "",
        //     car_type: car_type,
        //     amount: amount,
        //     pickcityLng:0,
        //     pickcityLat:0,
        //     drpcityLng:0,
        //     drpcityLat:0})

            const bookingDetails = await axios.post("https://bangaloreairporttaxis.in/api/carbook",{
            type: props.subCatVal,
            name: fname,
            mobile: phoneNumber,
            email: email,
            pickup_from: pickupLoc,
            drop_to : dropLoc,
            date_time: dateTime,
            return_date_time: "",
            car_type: car_type,
            amount: amount,
            pickcityLng:0,
            pickcityLat:0,
            drpcityLng:0,
            drpcityLat:0
            })
            .then((res)=>
                {
                    setLoadingModalIsOpen(false);
                    setSuccessDetails(res.data.message)
                    setSuccessModalIsOpen(!successModalIsOpen)
                }
            )
            .catch((err)=>console.log(err))
            setGetFareModalIsOpen(!getFareModalIsOpen);

            setTimeout(() => {
                window.location.reload();
              }, 5000);
        
    }

    const successModalClose = ()=>{
        setSuccessModalIsOpen(!successModalIsOpen);
    }

    const autoFill=()=>{
        setFname(localStorage.getItem('fname'))
        setPhoneNumber(localStorage.getItem('phoneNumber'))
        setEmail(localStorage.getItem('email'))
        document.getElementById('fname').value = localStorage.getItem('fname');
        document.getElementById('phoneNumber').value = localStorage.getItem('phoneNumber');
        document.getElementById('email').value = localStorage.getItem('email');
        
    }

  return (
    <div className='ml-1 mr-1 rounded bg-white mt-5'>
        <p className='text-center fw-bolder pt-1 fs-13'>Book Your Taxi Online !</p>
        <div className="container-fluid fs-13">
            <div className="row text-center">
                <div className="col">
                    <button 
                        className={`shadow-none border-customGray rounded w-100 ${props.mainCat.val === 'airport' ? 'bg-yellow':'bg-white'}`} 
                        onClick={props.majorCategory} 
                        value={"airport"}
                        style={{paddingBottom: '20px'}}>
                        Airport
                    </button>
                </div>
                <div className="col">
                    <button 
                        className={`shadow-none border-customGray rounded w-100 ${props.mainCat.val === 'outstation' ? 'bg-yellow':'bg-white'}`} 
                        onClick={props.majorCategory} 
                        value={"outstation"}
                        style={{paddingBottom: '20px'}}>
                        Outstation
                    </button>
                </div>
                <div className="col rounded">
                    <button 
                        className={`shadow-none border-customGray rounded w-100 ${props.mainCat.val === 'local' ? 'bg-yellow':'bg-white'}`} 
                        onClick={props.majorCategory} 
                        value={"local"}
                        style={{paddingBottom: '20px'}}>
                        Local
                    </button>
                </div>
            </div>

            <div className="row gap-3 ps-3 pe-3 mt-1 text-center">
                <div className="col ">
                    <button 
                        className={`shadow-none border-customGray rounded w-100 ${props.subCatVal === props.mainCat.subCategory1.value ? 'bg-yellow':'bg-white'}`}
                        onClick={props.minorCategory} 
                        value={props.mainCat.subCategory1.value}
                        style={{paddingBottom: '20px'}}>
                        {props.mainCat.subCategory1.catName}
                    </button>
                </div>
                <div className="col rounded ">
                    <button 
                        className={`shadow-none border-customGray rounded w-100 ${props.subCatVal === props.mainCat.subCategory2.value ? 'bg-yellow':'bg-white'}`} 
                        onClick={props.minorCategory} 
                        value={props.mainCat.subCategory2.value}
                        style={{paddingBottom: '20px'}}>
                        {props.mainCat.subCategory2.catName}
                    </button>
                </div>
            </div>

                <div className="mb-custom-4 mt-2">
                    <label htmlFor="name" className="form-label fs-13">Enter Your Name</label>
                    <div className='form-control'>
                        <input type="text" className="inputFname shadow-none fs-13 w-80" onChange={handleName} id='fname' placeholder='Name' autoComplete="off"/>
                        {localStorage.getItem('fname') && <span className='fs-13 bg-secondary-subtle' onClick={autoFill} style={{padding: '3px 4px'}}>{localStorage.getItem('fname')}</span>}
                    </div>
                                        
                </div>
                <div className="mb-custom-4">
                    <label htmlFor="contact" className="form-label fs-13">Enter Mobile Number</label>
                    <input type="number" className="form-control shadow-none fs-13" onChange={handleNumber} id='phoneNumber' placeholder='Phone Number'/>
                    {!isNumberValid && (
                        <p className="text-danger fs-13">Please enter a valid mobile number.</p>
                    )}
                </div>
                <div className="mb-custom-4">
                    <label htmlFor="email" className="form-label fs-13">Enter Your Email</label>
                    <input type="email" className="form-control shadow-none fs-13" onChange={handleEmail} id='email' placeholder='Email' autoComplete="off"/>
                    {!isEmailValid && <p className="text-danger fs-13">Please enter a valid email address.</p>}
                </div>
                
                <PickUp
                    pickUp={props.pickUp}
                    handlePickUpModalOpen={handlePickUpModalOpen}
                    handlePickUp={handlePickUp}
                    pickUpModalIsOpen = {pickUpModalIsOpen}
                    resPickUp={props.resPickUp}
                    handlePickUpChoosenPlace={handlePickUpChoosenPlace}
                    subCatVal = {props.subCatVal}
                />

                <DropUp
                    dropUp={props.dropUp}
                    handleDropUpModalOpen={handleDropUpModalOpen}
                    handleDropUp={handleDropUp}
                    dropUpModalIsOpen = {dropUpModalIsOpen}
                    resDropUp={props.resDropUp}
                    handleDropUpChoosenPlace={handleDropUpChoosenPlace}
                    subCatVal = {props.subCatVal}
                />

                <div className="row mb-2">
                    <div className="col-sm mb-custom-4">
                        
                        <DemoItem label="Choose Date">
                            <MobileDatePicker 
                                views={["year", "month", "day"]}
                                label="Pick Up Date"
                                onChange={(date)=>setSelectedDate(date)}
                                inputFormat="dd-MM-yyyy"
                                disablePast
                                value={selectedDate}
                            />
                        </DemoItem>
                        
                    </div>
                    <div className="col-sm">
                        {/* <DemoItem label="Choose Time">
                            <MobileTimePicker 
                                label="Pick Up Time"
                                onChange={(time)=>{
                                    setSelectedTime(time);
                                }}
                                value={selectedTime}
                            />
                        </DemoItem> */}
                        <DatePicker
                            handleModalOpen ={handleModalOpen}
                            selectTime = {selectTime}
                            ModalIsOpen={ModalIsOpen}
                            showTime = {showTime}
                        />
                    </div>
                </div>

                <Book
                    getFareModal= {getFareModal}
                    getFareModalIsOpen={getFareModalIsOpen}
                    getFare = {getFare}
                    carTypes={props.carTypes}
                    bookNow={bookNow}
                    isLoading= {props.isLoading}
                    subCatVal = {props.subCatVal}
                />

                <Loading
                    loadingModalIsOpen = {loadingModalIsOpen}
                />

                    <Success
                        successModalIsOpen={successModalIsOpen}
                        successDetails = {successDetails}
                        successModalClose = {successModalClose}
                    />
                
        </div>
    </div>
  )
}

export default Form
