import React from 'react'
import Bag from '../assets/images/bag_icon.webp';
import Service from '../assets/images/service_icon.webp';
import Taxi from '../assets/images/taxi_icon.webp';

const Feature = () => {
  return (
    <div className="bg-grad pt-5">
        <div className='ml-1 mr-1 rounded bg-black rounded'>
            <div className="container-fluid">
                <div className="row pt-3 text-center">
                    <div className="col-sm"></div>
                    <div className="col-sm col-md-3 text-white">
                        <h4 className='color-yellow'>Taxi Service</h4>
                        <p>Book Eazy Cabs service in Banglore at most affordable taxi fare for Local or Outstation rides</p>

                        <h4 className='color-yellow'>Office Pickups</h4>
                        <p>Eazy Cabs offers an office cab service to make the life of all office goes eazy.</p>

                        <h4 className='color-yellow'>24/7 Available</h4>
                        <p>Best taxi service with 24*7 assistance, focused on comfortable journey and women safety.</p>
                    </div>
                    <div className="col-sm"></div>
                </div>
            </div>
        </div>
            <div className="fw-bold mt-5 ml-1 mr-1">
                <h4 className='fw-bold'>About Us</h4>
                <p>Eazy Cabs</p>
                <p className='fw-300'>Eazy Cabs is a Bangalore based company working towards excellence in the Transportation, and believe in pursuing business through innovation and technology. Our team comes with several years of industry experience, and comprise of a highly motivated set of specialists. We are a experienced, dedicated and energetic team, cognizant of the market and our customer's requirements. </p>

                <h4 className='mt-5 fw-bold'>Clients Feedback</h4>
                <p className='mt-5'>Dilip Prakash</p>
                <p className='lh-1'>Banglore </p>
                <p className='fw-300'> We had a very good airport taxi service and had experienced everything that we have seen on website. Driver was so friendly and made everything eazy for us. </p>

                <p className='mt-5'>Monisha Kamat</p>
                <p className='lh-1'>Banglore </p>
                <p className='fw-300'>I had booked a Eazy Cabs for three days.The driver was familiar with routes and vehicle was neat.Thank you for providing a wonderful ride. </p>

                <p className='mt-5'>Richar Branson</p>
                <p className='lh-1'>Banglore </p>
                <p className='fw-300'>The service was great. The driver too was humble and polite. The whole trip was great because of the driver.</p>
            </div>
                <div className="bg-brown mt-5 container-fluid rounded text-white">
                    <h4 className='pb-3 pt-3'>Help & Support</h4>
                    <p><i className="fa-solid fa-location-dot color-yellow pe-3"></i> 366-367, 7th Cross Rd, Devarachikkana Halli, Bengaluru, Karnataka 560076</p>
                    <p><i className="fa-regular fa-envelope color-yellow pe-3"></i><a href='mailto: eazycarrentals12@gmail.com'>eazycarrentals12@gmail.com</a></p>
                    <p className='pb-3'><i className="fa-solid fa-phone color-yellow pe-3"></i><a href='tel: 9620088857'>+91-9620088857</a></p>
                </div>

            <div className="container-fluid">
                <div className="row text-center bg-black text-white pt-3 pb-3">
                    <div className="col">
                        <img src={Bag} alt="" className='icon-img me-3'/><span className=' d-inline-block'>Office Pickup</span>
                    </div>
                    <div className="col">
                        <img src={Service} alt=""  className='icon-img me-3'/><span className=' d-inline-block'>24/7 Service</span>
                    </div>
                    <div className="col">
                     <img src={Taxi} alt=""  className='icon-img me-3'/><span className=' d-inline-block'>Taxi Service</span>
                    </div>
                </div>
            </div>
            
        
       
    </div>
  )
}

export default Feature
