import React from 'react'
import Logo from '../assets/images/logo_image.webp'

const Topframe = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col"><img src={Logo} alt=""  className='logo mt-3'/></div>
        <div className="col"><p className='bg-white p-2 mt-4 text-center' style={{borderRadius: '10px'}}>Airport Taxi Bengaluru</p></div>
      </div>
        
        <div className="text-center text-white mt-4">
            <p className='fw-bold'>NEED A RIDE ? JUST CALL</p>
            <h2 className='lh-1' style={{fontWeight: 'bolder'}}><a href='tel: 9620088857'>+91-9620088857</a></h2>
            <p className='mt-3'>Whether you enjoy city breaks or extended holidays in the sun, you can always improve your travel experiences by staying in a small.</p>
        </div>
    </div>
  )
}

export default Topframe
