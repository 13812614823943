import React from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {FaSearch} from 'react-icons/fa'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxHeight: '95%',
    borderRadius: '20px',
    backgroundColor: '#EEE9E3',
    boxShadow: 24,
    p: 4,
    paddingBottom: '60px',
    overflow: 'scroll',
  };


const PickUp = ({pickUp,handlePickUpModalOpen,handlePickUp,pickUpModalIsOpen,resPickUp,handlePickUpChoosenPlace,subCatVal}) => {
  return (
    <div className="mb-custom-4">
                    <label className="form-label fs-13" htmlFor="pickup">Enter Pickup Address</label>

                    {subCatVal === 'cab-from-airport' ? 
                      <button 
                      className={`form-control text-start text-dark  shadow-none fs-13`}
                      disabled={true}
                      >
                        Bangalore Airport Lounge, Kempegowda Int'l Airport Rd, A - Block, Gangamuthanahalli, Karnataka, India
                      </button>
                    :
                    //   <button 
                    //   className={`form-control text-start ${pickUp === ''?'color-customGray':'text-dark'}  shadow-none fs-13`} 
                    //   onClick={handlePickUpModalOpen}
                    //   >
                    //   {pickUp === ''? 'Pick Up Location': pickUp}
                    // </button>
                    <input type="text" placeholder='Pickup Location' className="form-control text-start shadow-none fs-13"
                      onChange={(e)=>handlePickUp(e.target.value)}
                    />
                    }
                   
                   <Modal
                        open={pickUpModalIsOpen}
                        onClose={handlePickUpModalOpen}
                    >
                        <Box sx={style}>
                            <div className='input-wrapper mb-5'>
                                <FaSearch/>
                                <input type="text" placeholder='Search Pickup place' className=" w-100 bg-transparent border-none shadow-none"
                                    onChange={(e)=>handlePickUp(e.target.value)}
                                />
                                <button className='go-btn bg-yellow text-dark' onClick={()=> handlePickUpChoosenPlace(pickUp)}>Go</button>
                            </div>
                            {resPickUp.status === 'OK' ? resPickUp.predictions.map((places,id)=>{
                                    return <div key={id} className='text-center mt-3' onClick={()=> handlePickUpChoosenPlace(places.description)}>{places.description}</div>
                            }) : <div className='text-center mt-3'>No Places Searched</div>}
                        </Box>
                    </Modal>
        </div>
  )
}

export default PickUp
